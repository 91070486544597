import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from "react-router-dom";

const Head = ({ title, description, image }) => {
 
  const location = useLocation()
  const pathname = location.pathname

  const metadata = {
    defaultTitle: "Game, Set, Blog",
    defaultDescription: "GameSetBlog.com - Tennis news, analysis, and opinion delivered with deadpan humor and a touch of sass. Grand Slams, ATP, and WTA forehands, backhands, overheads, tweeners, and dropshots.",
    siteURL: "https://gamesetblog.com",
    defaultImage: "../../background-images/metadataImage.png",
    twitterUsername: "@GameSet_Blog"
  }

  const seo = {
    title: title || metadata.defaultTitle,
    description: description || metadata.defaultDescription,
    image: `${metadata.siteURL}${image || metadata.defaultImage}`,
    url: `${metadata.siteURL}${pathname}`,
  };

  return (
    <Helmet title={title} defaultTitle={seo.title} titleTemplate={``}>
      <html lang="en" />

      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={metadata.twitterUsername} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />

      <meta name="google-site-verification" content="UWdDiArrGivUbpWkJwWyFiGgMSkGbPiUXXYmB-DqkWE" />
      <link rel="canonical" href={`${metadata.siteURL}${pathname}`} />
    </Helmet>
  );
};

export default Head;