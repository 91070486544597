import React from 'react'

import './Footer.css'


export default function Footer(props) {
  
  return(
    <>
        <div className="footer-container">

            <p className="footer-copy">ALL RIGHTS RESERVED. © 2023</p>

        </div>
    </>
  )
}